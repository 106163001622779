@font-face {
    font-family: 'Ray-Ban Sans';
    src: url('Ray-BanSans-Bold.woff2') format('woff2'),
        url('Ray-BanSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ray-Ban Sans';
    src: url('Ray-BanSans-Light.woff2') format('woff2'),
        url('Ray-BanSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ray-Ban Sans';
    src: url('Ray-BanSans-Regular.woff2') format('woff2'),
        url('Ray-BanSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ray-Ban Sans Inline Bold 1';
    src: url('Ray-BanSansInline-Bold1.woff2') format('woff2'),
        url('Ray-BanSansInline-Bold1.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ray-Ban Sans Inline Bold 50';
    src: url('Ray-BanSansInline-Bold50.woff2') format('woff2'),
        url('Ray-BanSansInline-Bold50.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ray-Ban Sans Inline Light 100';
    src: url('Ray-BanSansInline-Light100.woff2') format('woff2'),
        url('Ray-BanSansInline-Light100.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ray-Ban Sans Inline Bold 100';
    src: url('Ray-BanSansInline-Bold100.woff2') format('woff2'),
        url('Ray-BanSansInline-Bold100.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ray-Ban Sans Inline 100';
    src: url('Ray-BanSansInline-Regular100.woff2') format('woff2'),
        url('Ray-BanSansInline-Regular100.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ray-Ban Sans Var';
    src: url('Ray-BanSansVar.woff2') format('woff2'),
        url('Ray-BanSansVar.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

