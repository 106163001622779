@import './vars.scss';

#FeatureShare {
  .c1 {
    padding: 8em 4em 0 0;
    text-align: right;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
  .g1, .g2 {
    width: 100%;
    position: relative;
  }
  .description {
    max-width: 15em;
    position: absolute;
    text-align: right;
    right: 0;
  }
}

.App[data-lang=fr], .App[data-lang=fr-CA] {
  #FeatureShare {
    .g2 {
      .description {
        max-width: 14em;
      }
    }
  }
}

.App[data-lang=it] {
  #FeatureShare {
    .g2 {
      .description {
        max-width: 16.5em;
      }
    }
  }
}

@media
  (min-aspect-ratio: $media-ipad-min-aspect) and (min-width: $media-ipad-min-width) and (max-width: $media-ipad-max-width) {
  #FeatureShare {
    .c1 {
      padding: 0!important;
      top: 2rem;
      right: 2rem!important;
      left: auto!important;
    }
    .description {
      right: 1rem!important;
      top: 2rem!important;
    }
  }
}

@media (max-width: $media-public-max-width) {
  #FeatureShare {
    .c1 {
      padding: 0!important;
      top: 1rem;
      right: 1rem!important;
      left: auto!important;
    }
    .description {
      right: 1rem!important;
    }
  } 
}