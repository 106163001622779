@import './vars';

$escapeWidth: 1.75em;
$escapeMargin: 1em;

#ADAPanel {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: black;
  font-size: 1rem;
  height: 20em;
  z-index: 2;
  padding: #{$escapeWidth + $escapeMargin} 1em 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  color: white;
  font-family: 'SFProDisplay';
  transform: translateY(100%);
  transition: transform 0.25s ease;
  -webkit-tap-highlight-color: initial;

  &.active {
    transform: translateY(0);
  }

  .escape {
    display: block;
    position: absolute;
    width: $escapeWidth;
    height: $escapeWidth;
    top: $escapeMargin;
    left: $escapeMargin;
    
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .language {
    display: flex;
    flex-flow: column;
    align-items: center;
    align-self: center;

    button {
      margin-bottom: 1em;
      font-size: 1rem;
      background: none;
      color: white;
      border: 0.1rem solid currentColor;
      border-radius: 1em;
      padding: 0.25em;
      width: 4em;
      appearance: none;

      &.active {
        color: $red;
      }
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    align-self: flex-start;

    .back, .fwd {
      display: flex;
      flex-flow: column;
      svg { width: 2.5em; height: auto; margin-bottom: 1em; }
    }

    .links {
      display: flex;
      flex-flow: column;
      text-align: center;
      margin: 0 5em auto;
      justify-content: space-around;
    }

    .link {
      display: block;
      color: white;
      width: 8em;
      border: 0.1rem solid white;
      border-radius: 1em;
      margin-bottom: 1em;
      text-decoration: none;
      padding: 0.25em 0;
      &:last-of-type { margin-bottom: 0; }
      &.current {
        color: $red;
        border: 0.1rem solid $red;
      }
    }
  }

  .content {
    align-self: flex-start;
    font-size: 1.5rem;
    width: 25%;
    font-family: 'SFProDisplay';
    height: calc(100% - 2.75em);
    padding-bottom: 1em;
    overflow: scroll;
    box-sizing: border-box;

    h1 {
      font-size: 1em;
      margin: 0 0 1.5em 0;
    }
    p {
      font-size: 0.8em;
    }
  }
}

@media (max-width: $media-public-max-width) {
  #ADAPanel {
    display: none;
  } 
}