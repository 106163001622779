@import "./vars.scss";

#BSOverlay {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  box-sizing: border-box;
  background: black;
  z-index: 1;
  color: white;
  font-family: 'SFProDisplay';
  padding: 3em;
  overflow: scroll;
  user-select: auto;

  button {
    background: transparent;
    padding: 0.5em 1em;
    color: white;
    border: 1px solid white;
    border-radius: 0.2em;
  }

  .close {
    position: absolute;
    top: 1.5em; right: 1.5em;
    width: 5em; height: 5em;
    border-radius: 50%;
    padding: 1.5em;
    svg {
      width: 100%; height: 100%;
    }
  }

  .title {
    font-size: 3em;
  }

  .info {
    font-size: 2em;
    list-style-type: none;
    button { font-size: 2rem; }
    line-height: 1.5em;
    padding-left: 1em;
  }

  .radio-list {
    margin-bottom: 1em;
    display: flex;
    flex-flow: column;

    input[type=radio] {
      width: 2em;
      height: 2em;
      margin-right: 1em;
    }
  
    label {
      display: flex;
      align-items: center;
      margin-right: auto;
    }
  }

  .errors {
    color: red;
  }
  
}

#BSOverlayAccess {
  color: initial;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  input {
    background: none;
    color: white;
    font-size: calc(33rem / 4);
    letter-spacing: 0.6em;
    text-align: center;
    width: 33rem;
    border: none;
    outline: none;
    padding-bottom: 1rem;
    border-bottom: 5px solid white;
    border-radius: 0;
    margin-bottom: 4rem;
  }

  .passcode {
    width: 33rem;
    font-size: 5em;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;

    span {
      border-bottom: 4px solid white;
      display: inline-block;
      padding-bottom: 0.1em;
      color: white;
      text-align: center;
    }
  }

  /*
    Theme: numeric
  */
  .numeric-theme {
    max-width: 33rem;
    margin: 0 auto;
    color: white;
    background: black;
    padding: 0;
  }

  .numeric-theme + .simple-keyboard-preview {
    max-width: 33rem;
  }

  .simple-keyboard.hg-theme-default.numeric-theme
    .hg-button.hg-standardBtn.hg-button-at {
    max-width: none;
  }

  .hg-button {
    background: transparent;
    border-bottom: 2px solid white;
    border-radius: 0;
    margin: 0;
    font-size: 2rem;
    height: 3em;
    &:nth-of-type(1) {
      border-right: 1px solid white;
    }
    &:nth-of-type(2) {
      border-left: 1px solid white;
      border-right: 1px solid white;
    }
    &:nth-of-type(3) {
      border-left: 1px solid white;
    }
  }

  .hg-row {
    margin: 0;
    &:last-of-type {
      .hg-button {
        border-bottom: none;
      }
    }
  }
}