@import "./vars";

#FeaturesNavMobile {
  position: fixed;
  width: calc(100% - 1em);
  display: flex;
  bottom: 1em;
  left: 0.5em;
  justify-content: space-evenly;
  z-index: 1;
  -webkit-tap-highlight-color: initial;

  img {
    max-width: 2rem;
    max-height: 2rem;
    width: auto;
    height: auto;
    margin: auto;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.active {
      opacity: 0;
    }
  }

  .link {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    width: 5rem;
    height: 3.5rem;

    &.current {
      color: $red;

      img {
        &.passive { opacity: 0 }
        &.active { opacity: 1 }
      }
    }
  }

  .icon {
    display: block;
    text-align: center;
    flex-grow: 1;
    position: relative;
  }

  .label {
    font-family: 'SFProDisplay';
    display: block;
    font-size: 0.8em;
    margin-top: auto;
  }
}