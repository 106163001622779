.error-overlay {
  position: fixed;
  top: 0; left: 0;
  bottom: 0; right: 0;
  background: black;
  color: white;
  font-family: 'Ray-Ban Sans';
  font-size: 1rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: none;
}

@media (orientation: landscape) {
  .error-overlay {
    &.is-landscape { display: flex; }
  }
}