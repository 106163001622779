@import './vars.scss';

#FeaturesNav {
  position: absolute;
  font-size: 1rem;
  left: 3em;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  -webkit-tap-highlight-color: initial;
  
  .label {
    font-size: 2.5em;
    font-family: 'Ray-Ban Sans';
    text-transform: uppercase;
    margin-right: auto;
    padding: 0 0.5em 0 0.15em;
  }
  
  .icon {
    position: relative;
    display: inline-flex;
    width: 5em;
    height: 5em;
    box-sizing: border-box;
    border-radius: 3em;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0);
    transition: box-shadow 0.4s ease;
    
    img {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      max-width: 3.25em;
      max-height: 3.25em;
      transform: translate(-50%, -50%);
      transition: opacity 0.4s ease;
      width: auto;
      height: auto;
      margin: auto;
    }
    
    img.passive {
      opacity: 1;
    }
    
    img.active {
      opacity: 0;
    }
    
    svg {
      filter: url(#features-nav-icon-dropshadow);
      width: 60%;
      max-height: 60%;
      margin: auto;
    }
  }  

  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgba(255,255,255,1);
    margin-top: 3em;
    position: relative;
    transition: color 0.4s ease;
    
    .outline {
      border: 0.12rem solid currentColor;
      border-radius: 3em;
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      transition: border-color 0.4s ease;
    }

    .bg { // TBD...
      content: '';
      position: absolute;
      top: 0; left: 0; bottom: 0; right: 0;
      border-radius: 2.5em;
      opacity: 1;
      background: rgba(0,0,0,0.2);
      transition: opacity 0.2s ease;
    }

    &.current {
      color: rgb(228,30,34);
      .outline {
        border: 0.12rem solid currentColor;
      }
      img.passive {
        opacity: 0;
      }
      img.active {
        opacity: 1;
      }
    }
  }

  &.hide-labels {
    .link.bg { display: none; }
    .label { display: none; }
    .icon { box-shadow: 0px 0px 5px 1px rgba(0,0,0,.25); }
  }
}

@media screen and (height: 1280px) and (width: 800px) {
  #FeaturesNav {
    .link { margin-top: 2em; }
    .label { font-size: 2.75em; }
    .icon {
      // width: 4.75em; height: 4.75em;
      img {
        max-width: 2.75em!important; max-height: 2.75em!important;
      }
      &.share {
        img {
          margin-bottom: 0.33em;
        }
      }
    }
  }
}

@media (max-width: $media-public-max-width) {
  #FeaturesNav {
    left: 1em;
    .label {
      font-size: 1.5em;
    }
    .icon {
      width: 2.25em;
      height: 2.25em;

      img.passive {
        max-width: 1.5em;
        max-height: 1.5em;
      }
      img.active {
        max-width: 1.25em;
        max-height: 1.25em;
      }
    }
    .link {
      margin-top: 1em;
    }
  }
}