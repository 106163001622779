#Demo {
  height: 100vh;
  width: 100vw;
  background: black;
  display: flex;

  .vid-cnr {
    margin: auto;
    height: 100%;
    width: 100%;
    display: block;
    transition: opacity 0.5s ease;
    text-align: center;
    z-index: 0;
    position: relative;
  }

  video {
    position: relative;
    width: auto;
    height: 100%;
    margin: 0 auto;
    z-index: 0;
  }
}

@keyframes video-in {
  from { opacity: 0 }
  to { opacity: 1 }
}