@import './vars.scss';

.lifestyle-gallery {
  position: absolute;
  top: 0; right: 0; left: 0; bottom: 0;
  font-size: 0em;
  display: grid!important;
  min-height: 100%;

  img {
    width: 100%; height: 100%;
    object-fit: cover;
    &:nth-of-type(2n) {
      transition-delay: 0.25s;
    }
    &:nth-of-type(3n) {
      transition-delay: 0.5s;
    }
    &:nth-of-type(4n) {
      transition-delay: 1s;
    }
  }

  .img-cnr {
    position: relative;

    .overlay {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      opacity: 1;
      transition: opacity 0.33s ease, transform 0.33s ease;
      -webkit-tap-highlight-color: initial;
      
      svg {
        width: 8rem;
        height: auto;
        margin: auto;
        overflow: visible;

        .highlight {
          transition: opacity 0.33s ease, transform 0.33s ease;
        }
      }

      &.hide {
        transform: scale(0);
        opacity: 0;
      }
    }

    &.active {
      img {
        transform: scale(1)!important;
      }
      .overlay {
        transform: scale(1)!important;
      }
    }
  }

  .video-close {
    position: absolute;
  }
}

@media (max-width: $media-public-max-width) {
  .lifestyle-gallery {
    .img-cnr {
      .overlay {
        svg {
          // margin: 1rem 1rem auto auto;
          width: 3rem;
        }
      }
    }
  } 
}