@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-BlackItalic.woff2') format('woff2'),
        url('SFProDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-BoldItalic.woff2') format('woff2'),
        url('SFProDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Black.woff2') format('woff2'),
        url('SFProDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Bold.woff2') format('woff2'),
        url('SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-HeavyItalic.woff2') format('woff2'),
        url('SFProDisplay-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-LightItalic.woff2') format('woff2'),
        url('SFProDisplay-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Heavy.woff2') format('woff2'),
        url('SFProDisplay-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Light.woff2') format('woff2'),
        url('SFProDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-MediumItalic.woff2') format('woff2'),
        url('SFProDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-RegularItalic.woff2') format('woff2'),
        url('SFProDisplay-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Medium.woff2') format('woff2'),
        url('SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Regular.woff2') format('woff2'),
        url('SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-SemiboldItalic.woff2') format('woff2'),
        url('SFProDisplay-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Semibold.woff2') format('woff2'),
        url('SFProDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProDisplay';
    src: url('SFProDisplay-Thin.woff2') format('woff2'),
        url('SFProDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

