#LanguageToggle {
  display: inline-block;
  vertical-align: bottom;;
  position: relative;
  font-size: 3rem;
  width: 1.5em;
  height: 1.5em;
  border-radius: 3em;

  .outline {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .options {
    display: flex;
    align-self: flex-end;
    justify-self: flex-end;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0; bottom: 0;
    border: 0.12rem solid white;
    transition: height 0.2s ease; // :|
    border-radius: 3em;
    box-sizing: content-box;

    button {
      font-family: 'Ray-Ban Sans';
      background: none;
      border: none;
      font-size: 3rem;
      line-height: 1em;
      width: 100%; height: 1.5em;
      padding: 0;
      font-weight: 400;
      color: white;
      outline: none;
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: 0;
      transition: transform 0.2s ease, opacity 0.2s ease;
    }

    &.open {
      height: 6em;
    }
  }
}

@media screen and (max-height: 1280px) {
  #LanguageToggle {
    .options {
      border: 1px solid white;
      button {
        // font-size: 2.5rem;
      }
    }
  }
}