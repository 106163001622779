@import "./vars.scss";

#FeatureCapture {
  .c1 {
    .g1 {
      position: absolute;
      top: 15em; left: 4em;
      .description { max-width: 12em; }
    }
  }
  .c2 {
    .g1 {
      position: relative;
      text-align: right;
      top: 20em; right: 4em;
      .description { max-width: 16em; margin-left: auto; }
    }
    .g2 {
      position: absolute;
      top: 38em;
      right: 6em;
      display: flex;
      align-items: center;

      svg {
        width: 8rem; height: auto;
        path { fill: none; }
      }

      .record {
        display: inline-block;
        font-size: 1em;
        width: 12em;
        background: white;
        color: black;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 2em;
        padding: 0.5em;
        margin-left: 1em;

        .circle {
          display: inline-block;
          width: 1.5em; height: 1.5em;
          background: red;
          border-radius: 1em;
          margin-right: 1em;
        }

        .time {
          font-size: 1.5em;
        }
      }
    }
  }
  .c3 {
    .g1 {
      position: absolute;
      text-align: right;
      right: 6em;
      top: 20em;
      .description {
        max-width: 16em;
      }
    }
    .g2 {
      position: absolute;
      left: 9em;
      top: 26em;

      svg {
        width: 8em;
        path { fill: none; }
      }
    }
  }
  .c4 {
    .g1 {
      position: absolute;
      top: 15em; left: 4em;
      .description {
        font-size: 2em; max-width: 19em;
        font-weight: 300;
        strong { font-weight: 600; }
        
        .disclaimer {
          position: absolute;
          width: 100%;
          left: 0;
          top: 100%;
          font-size: 0.75em;
          color: $red;
        }
      }
    }
  }
}

@media
  (min-aspect-ratio: $media-ipad-min-aspect) and (min-width: $media-ipad-min-width) and (max-width: $media-ipad-max-width) {
    #FeatureCapture {
      .c2 {
        .g1 {
          right: 3em;
        }
      }
      .c3 {
        .g1 {
          right: 3em;
        }
      }
    }
}

@media (max-height: #{$bs-xd-height - 1px}) {
  #FeatureCapture {
    .c1 {
      .g1 {
        top: 10em;
      }
    }
    .c2 {
      .g1 {
        top: 14em;
      }
    }
    .c3 {
      .g1 {
        top: 14em;
      }
    }
    .c4 {
      .g1 {
        top: 12em;
      }
    }
  }
}